/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/



// Material Dashboard 2 React layouts
// import Users from "layouts/tables";

// @mui icons
import BlockIcon from "@mui/icons-material/Block";
import BoltIcon from "@mui/icons-material/Bolt";
import BookIcon from "@mui/icons-material/Book";
import CellTowerIcon from "@mui/icons-material/CellTower";
import DeleteIcon from "@mui/icons-material/Delete";
import OpacityIcon from "@mui/icons-material/Opacity";
import AnalyticsIcon from '@mui/icons-material/Analytics';
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import BloackedIPs from "layouts/BloackedIPs";
import Blogs from "layouts/Blogs";
import ConnectivityUsers from "layouts/ConnectivityUsers";
import ContactUsers from "layouts/ContactUsers";
import EnergyPPCUsers from "layouts/EnergyPPCUsers";
import EnergyUsers from "layouts/EnergyUsers";
import Waste from "layouts/Waste";
import Water from "layouts/Water";
import Location from "layouts/location";
// import SignIn from "layouts/authentication/sign-in";

const routes = [
  // {
  //   type: "collapse",
  //   name: "Dashboard",
  //   key: "dashboard",
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/dashboard",
  //   component: <Dashboard />,
  // },
  {
    type: "collapse",
    name: "Energy",
    key: "energy",
    icon: (
      <BoltIcon
        style={{
          height: "25px",
          width: "25px",
        }}
      />
    ),
    route: "/energy",
    component: <EnergyUsers />,
  },
  // {
  //   type: "collapse",
  //   name: "Finance",
  //   key: "finance-users",
  //   icon: (
  //     <CurrencyPoundIcon
  //       style={{
  //         height: "25px",
  //         width: "25px",
  //       }}
  //     />
  //   ),
  //   route: "/finance-users",
  //   component: <Finance />,
  // },
  // {
  //   type: "collapse",
  //   name: "Incurance",
  //   key: "incurance-users",
  //   icon: (
  //     <ShieldIcon
  //       style={{
  //         height: "25px",
  //         width: "25px",
  //       }}
  //     />
  //   ),
  //   route: "/incurance-users",
  //   component: <Incurance />,
  // },
  {
    type: "collapse",
    name: "Water",
    key: "Water",
    icon: (
      <OpacityIcon
        style={{
          height: "25px",
          width: "25px",
        }}
      />
    ),
    route: "/Water",
    component: <Water />,
  },
  {
    type: "collapse",
    name: "Waste",
    key: "Waste",
    icon: (
      <DeleteIcon
        style={{
          height: "25px",
          width: "25px",
        }}
      />
    ),
    route: "/Waste",
    component: <Waste />,
  },
  {
    type: "collapse",
    name: "Connectivity",
    key: "connectivity-users",
    icon: (
      <CellTowerIcon
        style={{
          height: "25px",
          width: "25px",
        }}
      />
    ),
    route: "/connectivity-users",
    component: <ConnectivityUsers />,
  },
  // {
  //   type: "collapse",
  //   name: "Appointments",
  //   key: "appointments",
  //   icon: (
  //     <EventAvailableIcon
  //       style={{
  //         height: "25px",
  //         width: "25px",
  //       }}
  //     />
  //   ),
  //   route: "/appointments",
  //   component: <Appointments />,
  // },
  {
    type: "collapse",
    name: "PPC",
    key: "energy-ppc",
    icon: (
      <AnalyticsIcon
        style={{
          height: "25px",
          width: "25px",
        }}
      />
    ),
    route: "/energy-ppc",
    component: <EnergyPPCUsers />,
  },
  {
    type: "collapse",
    name: "Contact Users",
    key: "contact-users",
    icon: (
      <PermContactCalendarIcon
        style={{
          height: "25px",
          width: "25px",
        }}
      />
    ),
    route: "/contact-users",
    component: <ContactUsers />,
  },
  {
    type: "collapse",
    name: "Location",
    key: "location",
    icon: (
      <AnalyticsIcon
        style={{
          height: "25px",
          width: "25px",
        }}
      />
    ),
    route: "/location",
    component: <Location />,
  },
  {
    type: "collapse",
    name: "Blogs",
    key: "blogs",
    icon: (
      <BookIcon
        style={{
          height: "25px",
          width: "25px",
        }}
      />
    ),
    route: "/blogs",
    component: <Blogs />,
  },
  {
    type: "collapse",
    name: "Blocked IPs",
    key: "blocked-ips",
    icon: (
      <BlockIcon
        style={{
          height: "25px",
          width: "25px",
        }}
      />
    ),
    route: "/blocked-ips",
    component: <BloackedIPs />,
  },
];

export default routes;
